<template>
  <div :class="$store.state.img_show == 'newLogin' ? 'new_login' : 'item-login'">
    <!-- <div class="login-logo"> -->
      <!-- <img alt="" height="100" src="./../../../static/logo1.png" width="200" /> -->
    <!-- </div> -->
    <div class="login-con" v-if="$store.state.img_show !== 'newLogin'">
      <p class="login-title claerfix kxTitle">
        <img alt="" src="./../../../static/logo1.png"/>
        <span v-if="$store.state.img_show == '科学化训练管理系统'">
          科学化训练管理系统
        </span>
        <span v-else>
          数字化体能训练管理系统
        </span>
      </p>
      
      <!-- <p class="login-title claerfix" v-else>
        <img alt="" height="200" src="./../../../static/logo3.png" width="200" />
        <span>
          数字化体能训练管理系统
        </span>
      </p> -->
      <div class="login-box">
        <!-- <p>用户登录</p> -->
        <div class="top_input">
          <p><i class="iconfont iconzhanghao"></i></p>
          <el-input placeholder="请输入账号" v-model="username" ref="input" @blur="onLength(false)">
          </el-input>  
        </div>
        <div class="top_input" style="margin-top: 20px;">
          <p><i class="iconfont iconmima"></i></p>
          <el-input
            @keyup.enter.native="login"
            placeholder="请输入密码"
            type="password"
            v-model="password"
            show-password
            @blur="onLength(true)"
          >
          </el-input>
        </div>
        <el-button @click="login">登 录</el-button>
      </div>
      <!-- <p>北京康比特体育科技股份有限公司</p> -->
      <!-- <el-checkbox v-model="checked" style="color:#fff;">记住密码</el-checkbox> -->
      <!-- <el-button type="primary" plain @click="login" v-loading.fullscreen.lock="fullscreenLoading">登陆</el-button> -->
    </div>
    <div class="newLogin-con" v-else>
      <p class="login-title claerfix">
        <img alt="" height="200" src="./../../../static/newLoginLogo.png" width="200" />
        <span>
          数字化体能训练管理系统
        </span>
      </p>
      <div class="newlogin-box">
          <el-input placeholder="请输入账号" v-model="username" ref="input" @blur="onLength(false)" prefix-icon="el-icon-s-custom">
          </el-input>
          <el-input
            @keyup.enter.native="login"
            placeholder="请输入密码"
            type="password"
            v-model="password"
            show-password
            @blur="onLength(true)"
            prefix-icon="el-icon-lock"
          >
          </el-input>
        <el-button @click="login">登 录</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      checked: false,
      fullscreenLoading: false
    };
  },
  created() {
  },
  mounted() {
    console.log(this.$route.query.toUrl)
    this.$refs.input.focus()
  },
  methods: {
    login() {
      this.fullscreenLoading = true;
      // var toUrl = this.$route.query.toUrl ? this.$route.query.toUrl : '/management/architecture'
      var toUrl = ''
      if(this.$route.query.toUrl == '/smartCockpit') {
        toUrl = this.$route.query.toUrl
      } else {
        toUrl = '/management/architecture'
      }
      this.$axios
        .post(
          '/p/Login/logon',
          this.$qs({
            account: this.username,
            pwd: this.password,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$utils.message({
              type: 'success',
              message: res.data.message,
            });
            // let exp = new Date();
            // exp.setTime(exp.getTime() + 24 * 60 * 60 * 1000);//过期时间 2分钟
            // document.cookie = `${res.data.data.uk}=${res.data.data[res.data.data.uk]};expires = ${exp.toGMTString()}`;
            sessionStorage.setItem('userData', JSON.stringify(res.data.data));
            this.$store.state.userData = res.data.data;
            this.$router.push({ path: toUrl });
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
          }
          this.fullscreenLoading = false;
        });
    },
    onLength(type) {
      let messages = ''
      let opp = ''
      if(type) {
        messages = '密码最多为40个字符'
        opp = this.password
      } else {
        messages = '帐号最多为40个字符'
        opp = this.username
      }
      var realLength = 0;
  　　var len = opp.length;
  　　var charCode = -1;
  　　for(var i = 0; i < len; i++){
    　　charCode = opp.charCodeAt(i);
    　　if (charCode >= 0 && charCode <= 128) {
          realLength += 1;
    　　}else{
    　　　　// 如果是中文则长度加2
    　　　　realLength += 2;
    　　}
      }
      if(realLength > 40) {
        return this.$message({
          type: 'error',
          message: messages
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.item-login {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: transparent url('./../../../static/bjt2.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new_login{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: transparent url('./../../../static/newLoginBack.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video_login {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    margin-top: -100px;
  }
  .back_color {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.8);
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.login-con {
  // position: absolute;
  // top: 26%;
  // right: 10%;
  // text-align: center;
  width: 700px;
  margin: 0 auto;
  text-align: center;
  border-radius: 13px;
  z-index: 11;
  overflow: hidden;
  margin-top: 130px;
  .login-title {
    text-align: center;
    font-size: 50px;
    // background: #05061d;
    line-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0;
    // font-family:Microsoft YaHei;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(254, 254, 254, 1);
    z-index: 9;
    span {
    }
    img {
      display: block;
      width: 300px;
      height: auto;
      margin-bottom: 30px;
    }
    i{
      font-size: 100px;
      margin-bottom: 40px;
      display: block;
    }
  }

  // .kxTitle img{
  //     width: 300px;
  //     height: auto;
  // }

  & > p:nth-child(3) {
    font-size: 24px;
    font-family: PingFang SC;
    // font-family: Microsoft YaHei;
    color: #748395;
    font-size: 25px;
    font-weight: 400;
    margin-top: 40px;
  }

  .login-box {
    width: 80%;
    box-sizing: border-box;
    padding: 20px 40px;
    text-align: left;
    margin: 0 auto;

    .top_input{
      display: flex;
      align-items: center;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      p{
        width: 80px;
        height: 70px;
        background: rgba($color: #fff, $alpha: 0.2);
        text-align: center;
        line-height: 70px;
        i{
          font-size: 40px;
          color: #fff;
        }
      }
    }

    & > p:nth-child(1) {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-bottom: 25px;
      color: #fff;
    }

    /deep/ .el-input {
      height: 70px;
      border: none;

      input {
        height: 70px;
        background: none;
        border: none;
        color: #fff;
        font-size: 18px;
      }

      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #bababa;
        font-size: 18px;
      }
    }

    .el-input__inner {
      border-radius: 10px;
      border-color: #0f79cb;
      height: 55px;
    }

    .el-button {
      width: 100%;
      height: 60px;
      background: linear-gradient(90deg, #76FFC4 0%, #0055E9 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 20px;
      border-radius: 10px;
      border: none;
      margin-top: 100px;
    }
  }
}

.login-con > .el-input {
  width: 30%;
}

.login-logo {
  width: 100%;
  height: 20px;
  text-align: left;
  margin-top: 5%;

  img {
    width: 160px;
    height: 160px;
    margin-left: 15%;
  }
}

.newLogin-con{
  position: absolute;
  top: 23%;
  right: 4%;
  text-align: center;
  width: 600px;
  height: 500px;
  text-align: center;
  border-radius: 13px;
  z-index: 11;
  overflow: hidden;
  background: url('./../../../static/newLoginInput.png');
  background-size:  100% 100%;
  .login-title {
    text-align: center;
    font-size: 35px;
    // background: #05061d;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // font-family:Microsoft YaHei;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: rgba(254, 254, 254, 1);
    z-index: 9;
    span {
    }
    img {
      display: block;
      width: 60px;
      height: 60px;
      margin-top: 50px;
      margin-bottom: 10px;
    }
    i{
      font-size: 100px;
      margin-bottom: 40px;
      display: block;
    }
  }

  .newlogin-box{
    margin-top: 30px;
    .el-input{
      width: 60%;
      margin-bottom: 20px;
      /deep/ .el-input__inner {
        border-radius: 6px;
        border: 2px solid #006EFF;
        height: 48px;
        font-size: 14px;
        background: rgba(254, 254, 254, 0);
        color: #fff;
        padding-left: 45px;
      }

      /deep/ .el-input__inner:focus {
        border: 2px solid #09CAFB;
      }

      /deep/ .el-input__prefix{
        font-size: 22px;
        color: #fff;
        margin-left: 10px;
      }
    }
    .el-button {
      width: 60%;
      height: 50px;
      background: linear-gradient(90deg, #04EFFF 0%, #0056CB 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 20px;
      border-radius: 5px;
      border: none;
      margin-top: 25px;
    }
  }
}
</style>
<style lang="scss">
.login-box {
  .el-input__inner {
    border-radius: 6px;
    border: 2px solid #e5e5e5;
    height: 48px;
    font-size: 14px;
  }

  .el-input__inner:focus {
    border: 2px solid #0f79cb;
  }

  .el-input__prefix {
    margin-top: 16px;
    margin-left: 4px;

    i {
      font-size: 17px;
    }
  }
}
</style>
